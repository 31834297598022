<template>
  <v-container>
    <v-row class="mb-4">
      <v-col v-show="isWeb" cols="12" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Alias <span class="danger500Color--text">*</span>
        </p>
        <v-text-field
          class="can-disable-selector"
          placeholder="Input Alias"
          outlined
          dense
          :required="isWeb"
          :disabled="lang === 'en' || disabledEdit"
          :rules="isWeb ? [aliasRules.required] : []"
          :hide-details="!isAliasError"
          v-model="aliasVal"
          @change="setCheckedAlias"
          @keypress="isNotSpecialChar"
          @paste="pasteData"
        >
        </v-text-field>
        <v-row
          align="center"
          justify="start"
          class="flex-nowrap"
          t-data="permalink-container"
          no-gutters
        >
          <p class="value-message small mb-0 secondaryTextColor--text">
            Permalink : <a @click="clickPackageLink">{{ packageLink }}</a>
          </p>
        </v-row>
      </v-col>
      <v-col cols="12" t-data="info-input" class="mt-4">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Title
          <span class="danger500Color--text">*</span>
        </p>
        <v-text-field
          class="can-disable-selector"
          placeholder="Content title"
          outlined
          dense
          required
          :disabled="disabledEdit"
          :rules="[titleRules.required]"
          v-model="titleVal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" t-data="info-input">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Thumbnail
          <span class="danger500Color--text">*</span>
        </p>
        <image-dropzone
          :thumbnail="thumbnailVal"
          :is-error="isImageError"
          :error-msg="imageErrorMessage"
          :disabled="disabledEdit"
          :is-square="true"
          subtitle="Recommended image size 500x500px."
          @update="updateImage"
          @remove="removeImage"
        ></image-dropzone>
      </v-col>
      <v-col v-if="isWeb" cols="12" t-data="info-input" class="mt-4">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Alt Text
        </p>
        <v-text-field
          class="can-disable-selector"
          placeholder="Alt Text"
          outlined
          dense
          required
          :disabled="disabledEdit"
          v-model="thumbnailAltVal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" t-data="info-input" :class="{'mt-4': !isWeb}">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Cover image
          <span class="danger500Color--text">*</span>
        </p>
        <image-dropzone
          :thumbnail="coverImageVal"
          :is-error="isCoverImageError"
          :error-msg="coverImageErrorMessage"
          :disabled="disabledEdit"
          subtitle="Recommended image size 800x600px."
          @update="updateCoverImage"
          @remove="removeCoverImage"
        ></image-dropzone>
      </v-col>
      <v-col v-if="isWeb" cols="12" t-data="info-input" class="mt-4">
        <p class="edit-field-label sub-body-bold" t-data="info-label">
          Alt Text
        </p>
        <v-text-field
          class="can-disable-selector"
          placeholder="Alt Text"
          outlined
          dense
          required
          :disabled="disabledEdit"
          v-model="coverImageAltVal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="mt-4" t-data="info-input">
        <quill-editor
          ref="quillContent"
          :value="description"
          :options="editorOption"
          @input="inputDescription"
          @blur="blurDescription"
        ></quill-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import {quillEditor, Quill} from 'vue-quill-editor'
import ImageUploader from 'quill-image-uploader'
import {mapActions, mapState} from 'vuex'

import client from '@/apis/http-client'
import ImageDropzone from '@/components/ImageDropzone'
import MyLink from '@/constants/quill-link'
import {imageTypeErrorMsg} from '@/constants/constants'
import {convertFileToBase64, isAliasAllow, isEnglishAlphabet, isJpg, isPng, isThaiAlphabet} from '@/helper/helper'
import {displayTypeEnum} from '@/constants/package'

Quill.register('modules/imageUploader', ImageUploader)
Quill.register(MyLink)

export default {
  name: 'PackageContentByLang',
  components: {
    ImageDropzone,
    quillEditor
  },
  props: {
    alias: {type: String},
    description: {type: String},
    content: {type: Object},
    disabledEdit: {type: Boolean},
    lang: {type: String},
    isImageError: {type: Boolean},
    imageErrorMessage: {type: String},
    isCoverImageError: {type: Boolean},
    coverImageErrorMessage: {type: String}
  },
  mounted() {
    this.isAliasError = false
    if (this.disabledEdit) {
      this.disableQuill()
    } else {
      this.enableQuill()
    }
  },
  watch: {
    disabledEdit() {
      if (this.disabledEdit) {
        this.disableQuill()
      } else {
        this.enableQuill()
      }
    }
  },
  data() {
    return {
      packageLink: '',
      errorsOrg: [],
      isAliasError: false,
      aliasRules: {
        required: value => {
          if (value) {
            this.isAliasError = false
            return true
          }
          this.isAliasError = true
          return 'Alias is required'
        }
      },
      titleRules: {
        required: value => {
          if (value) {
            return true
          }
          return 'Title is required'
        }
      },
      editorOption: {
        modules: {
          imageUploader: {
            upload: file => {
              return new Promise(async resolve => {
                let base64 = await convertFileToBase64(file)
                let payload = {base64: base64, name: file.name}
                let url = await this.upload(payload)
                resolve(url)
              })
            }
          },
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold', 'italic', 'underline', 'strike'],
            [{list: 'ordered'}, {list: 'bullet'}],
            ['link', 'image']
          ]
        }
      }
    }
  },
  methods: {
    disableQuill() {
      this.$refs.quillContent.quill.enable(false)
      this.$refs.quillContent.quill.root.dataset.placeholder = ''
    },
    enableQuill() {
      this.$refs.quillContent.quill.enable(true)
      this.$refs.quillContent.quill.root.dataset.placeholder =
        'Insert text here ...'
    },
    setCheckedAlias(val) {
      this.setAlias(val)
    },
    validAlias(text) {
      if (!isEnglishAlphabet(text) && !isThaiAlphabet(text) && !isAliasAllow(text)) {
        return false
      } else {
        return true
      }
    },
    pasteData(event) {
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      if (!this.validAlias(pastedText)) {
        event.preventDefault();
      }
    },
    isNotSpecialChar(event) {
      if (!this.validAlias(event.key)) {
        event.preventDefault();
      }
    },
    clickPackageLink() {
      window.open(this.packageLink, '_blank')
    },
    inputDescription(val) {
      this.$emit('update:description', val)
    },
    updateImage(file) {
      if (file && file.name) {
        if (!isPng(file) && !isJpg(file)) {
          this.setImageError({
            lang: this.lang,
            isError: true,
            message: imageTypeErrorMsg
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setImageError({
            lang: this.lang,
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setImage({lang: this.lang, file})
          this.setImageError({lang: this.lang, isError: false, message: ''})
          this.$emit('changeThumbnail', file)
          return
        }
      } else {
        this.$emit('removeImage')
      }
    },
    removeImage() {
      this.$emit('removeImage')
      this.setImageError({
        lang: this.lang,
        isError: true,
        message: 'Image is required'
      })
    },
    updateCoverImage(file) {
      if (file && file.name) {
        if (!isPng(file) && !isJpg(file)) {
          this.setCoverImageError({
            lang: this.lang,
            isError: true,
            message: imageTypeErrorMsg
          })
          return
        } else if (!file || file.size > 1040000) {
          this.setCoverImageError({
            lang: this.lang,
            isError: true,
            message: 'Image size should be less than 1 MB!'
          })
          return
        } else {
          this.setCoverImage({lang: this.lang, file})
          this.setCoverImageError({
            lang: this.lang,
            isError: false,
            message: ''
          })
          this.$emit('changeCoverImage', file)
          return
        }
      } else {
        this.$emit('removeCoverImage')
      }
    },
    removeCoverImage() {
      this.$emit('removeCoverImage')
      this.setCoverImageError({
        lang: this.lang,
        isError: true,
        message: 'Image is required'
      })
    },
    async upload(payload) {
      try {
        let data = await client.secureHttpClient.post(
          `/por/api/uploadImage`,
          payload
        )
        return data.data.url
      } catch (e) {
        Vue.$log.error(e)
        return ''
      }
    },
    blurDescription() {
      this.$emit('changeDescription', this.description)
    },
    setLink(value) {
      const routeLang = this.lang === 'en' ? '/en' : ''
      const branch = this.packageObject.orgs.length > 0 ? this.packageObject.orgs[0] : '<Branch>'
      this.packageLink =
        ( value ) ? `${process.env.VUE_APP_WEB_URL}${routeLang}/package/${branch}/${value}` : ''
    },
    ...mapActions('packages', [
      'setAlias',
      'setCoverImage',
      'setCoverImageError',
      'setImage',
      'setImageError'
    ])
  },
  computed: {
    isWeb() {
      return (
        this.packageObject.display_type === displayTypeEnum.ALL ||
        this.packageObject.display_type === displayTypeEnum.WEB
      )
    },
    aliasVal: {
      get() {
        this.setLink(this.alias)
        return this.alias
      },
      set(value) {
        this.setLink(value)
        this.$emit('update:alias', value)
      }
    },
    titleVal: {
      get() {
        return this.content.title
      },
      set(value) {
        this.content.title = value
        this.$emit('changeContent', value)
      }
    },
    thumbnailVal: {
      get() {
        return this.content.thumbnail
      },
      set(value) {
        this.content.thumbnail = value
        this.$emit('changeThumbnail', value)
      }
    },
    thumbnailAltVal: {
      get() {
        return this.content.thumbnail_alt
      },
      set(value) {
        this.content.thumbnail_alt = value
        this.$emit('changeContent', value)
      }
    },
    coverImageVal: {
      get() {
        return this.content.cover_image
      },
      set(value) {
        this.$emit('changeCoverImage', value)
      }
    },
    coverImageAltVal: {
      get() {
        return this.content.cover_image_alt
      },
      set(value) {
        this.content.cover_image_alt = value
        this.$emit('changeContent', value)
      }
    },
    ...mapState('packages', ['packageObject'])
  }
}
</script>

<style scoped></style>
